<template>
    <div class="d-flex flex-column align-items-center">
        <div class="loader"
             :class="`${variant}-variant`"
             :style="loaderStyle" />
        <div v-if="text"
             class="loader-text"
             :style="textStyle">
            {{ text }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { LoaderVariantsEnum } from '../enums/loaderVariants';
    import { resolveCssValue } from '../helpers/resolveCssValue';

    const props = defineProps({
        variant: {
            type: String as () => LoaderVariantsEnum,
            default: LoaderVariantsEnum.PURPLE,
        },
        size: {
            type: [String, Number],
            default: 24,
        },
        thickness: {
            type: [String, Number],
            default: 4,
        },
        text: {
            type: String,
            default: '',
        },
        fontSize: {
            type: [String, Number],
            default: 12,
        },
    });

    const loaderStyle = computed(() => ({
        width: resolveCssValue(props.size),
        height: resolveCssValue(props.size),
        padding: resolveCssValue(props.thickness),
    }));

    const textStyle = computed(() => ({
        fontSize: resolveCssValue(props.fontSize),
    }));
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";

@function loaderBackground($color) {
    @return conic-gradient($color 0% 15%, transparent);
}

.loader {
    border-radius: 100%;
    animation: spin 400ms infinite linear;
    aspect-ratio: 1;
    mask: conic-gradient($black, $black), linear-gradient($black 0 0) content-box;
    mask-composite: subtract;

    &.purple-variant {
        background: loaderBackground($purple);
    }

    &.dark-purple-variant {
        background: loaderBackground($indigo);
    }

    &.light-purple-variant {
        background: loaderBackground($soft-purple);
    }

    &.black-variant {
        background: loaderBackground($black);
    }

    &.white-variant {
        background: loaderBackground($white);
    }
}

.loader-text {
    margin-top: 8px;
    color: $black;
}

.dark-theme {
    .loader-text {
        color: $white;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
</style>
